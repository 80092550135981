import { useState, useEffect } from 'react'
import { useMobile } from 'src/hooks/useMobile'

import './titlePage.scss'

// eslint-disable-next-line
const TitlePage = (dataCMS: any) => {
  // eslint-disable-next-line
  const [contentPage, setContentPage] = useState<any>()

  const { isMobile } = useMobile()

  useEffect(() => {
    const data = dataCMS.data.filter(
      // eslint-disable-next-line
      (item: any) =>
        item.name === '5.1 Título e breve descrição' &&
        (item.data.configuration === 'exibir website e aplicativo' ||
          item.data.configuration === 'exibir apenas website')
    )

    if (data.length) {
      setContentPage(data[0].data)
    } else {
      setContentPage(null)
    }
  }, [contentPage, dataCMS.data])

  // eslint-disable-next-line
  const useToggle = (initialState: any) => {
    const [toggleValue, setToggleValue] = useState(initialState)

    const toggler = () => {
      setToggleValue(!toggleValue)
    }

    return [toggleValue, toggler]
  }

  const [toggle, setToggle] = useToggle(false)

  return (
    <>
      {contentPage && (
        <div className="content-page mt-14 md:mt-20 layout__content">
          {contentPage.titlePage && (
            <h1 className="text-5xl md:text-6xl mb-6 font-bold text-neutral10 uppercase italic">
              {contentPage.titlePage}
            </h1>
          )}
          {contentPage.shortDescription && !isMobile && (
            <p className="text-sm text-deepGray">
              {contentPage.shortDescription}
            </p>
          )}

          {contentPage.shortDescription && isMobile && (
            <p className={`text-sm text-deepGray mobile-element ${toggle}`}>
              {contentPage.shortDescription}
              <button
                className={
                  !toggle
                    ? 'false text-blue02 text-base font-bold'
                    : 'true' + ' text-blue02 text-base font-bold'
                }
                onClick={setToggle}
              >
                {!toggle ? 'Ler mais' : 'Ler menos'}
              </button>
            </p>
          )}
        </div>
      )}
    </>
  )
}

export default TitlePage
