import Skeleton from 'react-loading-skeleton'
import Section from 'src/components/sections/Section'
import type { PropsWithChildren } from 'react'
import { useMobile } from 'src/hooks/useMobile'

// import 'src/components/product/ProductGrid/product-grid.scss'
import 'react-loading-skeleton/dist/skeleton.css'

interface Props {
  loading?: boolean
}

function GridSkeleton({ children, loading = true }: PropsWithChildren<Props>) {
  const { screenWidth } = useMobile()

  return loading ? (
    <Section className="layout__content mt-12 md:mt-14">
      <ul className="flex gap-4">
        <li className="w-[calc(100%/3-8px)]">
          <Skeleton height={screenWidth < 768 ? 165 : 220} />
        </li>
        <li className="w-[calc(100%/3-8px)]">
          <Skeleton height={screenWidth < 768 ? 165 : 220} />
        </li>
        <li className="w-[calc(100%/3-8px)]">
          <Skeleton height={screenWidth < 768 ? 165 : 220} />
        </li>
      </ul>
    </Section>
  ) : (
    <>{children}</>
  )
}

export default GridSkeleton
